import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getFunctions, httpsCallable } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBVZSfkEvfKc32WQwAhq4dsV5D3rcyaE8k",
  authDomain: "nodal-keyword-241119.firebaseapp.com",
  databaseURL: "https://nodal-keyword-241119.firebaseio.com",
  projectId: "nodal-keyword-241119",
  storageBucket: "nodal-keyword-241119.appspot.com",
  messagingSenderId: "148666098860",
  appId: "1:148666098860:web:b2afa4a1a96c62b400eb09"
};

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);

const sendEmail = httpsCallable(functions, 'sendEmail');

export {
  db,
  storage,
  sendEmail
};
