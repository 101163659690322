import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const CardContentNoPadding = styled(CardContent)(`
  padding: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);

const ProfileCard = (props) => {
    const { name, caption, summary, photo } = props;

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Card
            sx={{
                // height: 200
            }}
            elevation={3}
        >
            <CardContentNoPadding
                sx={{
                    display: 'flex',
                    flexDirection: isSmall ? 'column' : 'row',
                    gap: 2,
                    p: 0
                }}
            >
                <Avatar
                    src={photo}
                    alt={name}
                    sx={{
                        width: isSmall ? '100%' : '30%',
                        height: isSmall ? 180 : '100%',
                    }}
                    variant="square"
                />
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        pt: isSmall ? 0 : 2,
                        pb: isSmall ? 2 : 1,
                        pl: isSmall ? 2 : undefined,
                        pr: 2
                    }}
                >
                    <Typography variant="h5" gutterBottom>{name}</Typography>
                    <Typography variant="subtitle1" gutterBottom sx={{ color: theme.palette.text.secondary, fontStyle: 'italic' }}>{caption}</Typography>
                    <Typography variant="body2" sx={{ color: theme.palette.text.secondary }}>{summary}</Typography>
                </Box>
            </CardContentNoPadding>
        </Card>
    );
};

export default ProfileCard;
