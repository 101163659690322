import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

const Banner = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
      <Box
        sx={{
          bgcolor: 'background.paper',
          boxShadow: 1,
          borderRadius: 2,
          p: 4,
          mb: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          columnGap: 1
        }}
      >
        <img src={`${process.env.PUBLIC_URL}/graphics/butterfly.svg`} height={50} alt='A butterfly!' />
        <Typography variant={ isSmall ? 'h4' : 'h3' }>Butterflies Childminding</Typography>
      </Box>
  );
};

export default Banner;