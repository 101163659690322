import { useState } from 'react';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import MarkEmailReadTwoToneIcon from '@mui/icons-material/MarkEmailReadTwoTone';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { sendEmail } from '../adapters/firebaseAdapter';

const ContactForm = () => {
    const [name, setName] = useState();
    const [emailAddress, setEmail] = useState();
    const [phoneNumber, setPhone] = useState();
    const [contactReason, setContactReason] = useState();
    const [message, setMessage] = useState();

    const [validationErrors, setValidationErrors] = useState({});
    const [submitted, setSubmitted] = useState(false);
    const [complete, setComplete] = useState(false);

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const validateForm = () => {
        const errors = {};

        if (!name) {
            errors.name = 'Please let us know your name';
        }

        if (!(emailAddress || phoneNumber)) {
            errors.email = 'Please supply an email address or telephone number';
            errors.phone = 'Please supply an email address or telephone number';
        }

        if (!contactReason) {
            errors.reason = 'Please tell us the nature of your enquiry';
        }

        if (!message) {
            errors.message = 'Please let us know what your enquiry is about';
        }
        
        if (Object.keys(errors).length > 0) {
            setValidationErrors(errors);
            return false;
        } else {
            setValidationErrors({});
        }

        return true;
    };

    const submitForm = async () => {
        const formOk = validateForm();

        if (formOk) {
            console.log(`Name: ${name}`);
            console.log(`Email: ${emailAddress}`);
            console.log(`Phone: ${phoneNumber}`);
            console.log(`Reason: ${contactReason}`);
            console.log(`Message: ${message}`);

            setSubmitted(true);

            const result = await sendEmail({
                senderName: name,
                senderEmail: emailAddress,
                phone: phoneNumber,
                reason: contactReason,
                message
            });

            console.log(result);
            setComplete(true);
        }
    };

    const form = (
        <>
            <TextField
                id="name"
                label="Name"
                onChange={(e) => setName(e.target.value)}
                error={'name' in validationErrors}
                helperText={'name' in validationErrors ? validationErrors.name : undefined}
            />
            <TextField
                id="emailAddress"
                label="Email address"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                error={'email' in validationErrors}
                helperText={'email' in validationErrors ? validationErrors.email : undefined}
            />
            <TextField
                id="phoneNumber"
                label="Phone Number"
                type="tel"
                onChange={(e) => setPhone(e.target.value)}
                error={'phone' in validationErrors}
                helperText={'phone' in validationErrors ? validationErrors.phone : undefined}
            />
            <FormControl fullWidth>
                <InputLabel
                    id="reasonLabel"
                    error={'reason' in validationErrors}
                >
                    Reason for getting in touch
                </InputLabel>
                <Select
                    labelId="reasonLabel"
                    id="reasonSelect"
                    value={contactReason}
                    label="Reason for getting in touch"
                    onChange={(e) => setContactReason(e.target.value)}
                    error={'reason' in validationErrors}
                >
                    <MenuItem value='general'>General enquiry</MenuItem>
                    <MenuItem value='booking'>Booking</MenuItem>
                    <MenuItem value='policies'>Policies</MenuItem>
                </Select>
                <FormHelperText
                    error={'reason' in validationErrors}
                >{'reason' in validationErrors ? validationErrors.reason : undefined}</FormHelperText>
            </FormControl>
            <TextField
                id="message"
                label="Message"
                multiline
                rows={4}
                onChange={(e) => setMessage(e.target.value)}
                error={'message' in validationErrors}
                helperText={'message' in validationErrors ? validationErrors.message : undefined}
            />
            <Button
                variant="contained"
                onClick={submitForm}
            >
                Submit
            </Button>
        </>
    );

    const inProgress = <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            gap: 3
        }}
    >
        <CircularProgress size={50} />
    </Box>;

    const thanks = <Box
        sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 3
        }}
    >
        <MarkEmailReadTwoToneIcon
            sx={{
                fontSize: 64
            }}
        />
        <Typography>Thanks for your message! We'll get back to you soon.</Typography>
    </Box>;

    const selectElement = () => {
        if (!submitted) return form;
        if (!complete) return inProgress;
        return thanks;
    }

    return (
        <Box
            sx={{
                border: 1,
                borderRadius: 2,
                borderColor: theme.palette.border.primary,
                margin: isSmall ? 0 : 1,
                p: isSmall ? 2 : 3,
                display: 'flex',
                flexDirection: 'column',
                gap: 2
            }}
        >
            {selectElement()}
        </Box>
    );
};

export default ContactForm;
