import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FeesAndVacancies = () => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Fees and Vacancies
            </Typography>
            
            <Typography gutterBottom>
                Please get in touch if you would like information regarding any vacancies.<br />
                Children may arrive at 08:00 or later, and should be picked up by 17:30.
            </Typography>

            <Typography variant="h5" gutterBottom>
                Fees
            </Typography>
            
            <Typography>
                School pick-ups and drop-offs - £5.00 per hour.<br />
                <br />
                Full or part time - £4.50 per hour.<br />
                <br />
                Sickness or holidays of a child will be charged at half price.<br />
                I will not charge for my own sickness or holidays.<br />
                <br />
                Breakfast, lunch and snacks will be provided, though you can provide your own food if you wish to do so.<br />
                Nappies will not be provided.
            </Typography>
        </Box>
    );
};

export default FeesAndVacancies;
