import { useState, useLayoutEffect, useEffect } from 'react';

import Box from '@mui/material/Box';

const imageType = 'png';

const daisy = `${process.env.PUBLIC_URL}/graphics/daisy.${imageType}`;
const bluebell = `${process.env.PUBLIC_URL}/graphics/bluebell.${imageType}`;
const campion = `${process.env.PUBLIC_URL}/graphics/campion.${imageType}`;
const poppy = `${process.env.PUBLIC_URL}/graphics/poppy.${imageType}`;
const grass = `${process.env.PUBLIC_URL}/graphics/grass.${imageType}`;
const grassLeft = `${process.env.PUBLIC_URL}/graphics/grassLeft.${imageType}`;
const grassRight = `${process.env.PUBLIC_URL}/graphics/grassRight.${imageType}`;

const mainPanels = [
  daisy,
  bluebell,
  campion,
  poppy,
  grass
];

const meadowHeight = 80;

const Meadow = (props) => {
  const calcNumFlowers = (w) => parseInt(w / meadowHeight) - 1;

  const layoutFlowers = (nFlowers) => {
    const imagePaths = [];

    imagePaths.push(grassLeft);

    for (let i = 0; i < nFlowers; i++) {
      const choice = Math.floor(Math.random() * mainPanels.length);
      const imagePath = mainPanels[choice]
      imagePaths.push(imagePath);
    }

    imagePaths.push(grassRight);
  
    return imagePaths;
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [numFlowers, setNumFlowers] = useState(0)
  const [flowerSvgs, setFlowerSvgs] = useState([grassLeft, grassRight]);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    setNumFlowers(calcNumFlowers(windowWidth));
  }, [windowWidth]);

  useEffect(() => {
    if (numFlowers && numFlowers > 0) setFlowerSvgs(layoutFlowers(numFlowers));
  }, [numFlowers]);
  
  return (
    <Box
      sx={{
        alignSelf: 'center',
        bottom: 0,
        overflowX: 'hidden',
        display: 'flex',
        flexWrap: 'nowrap'
      }}
    >
      {flowerSvgs.map((imagePath, i) => (
        <img
          src={imagePath}
          height={meadowHeight}
          alt="meadow"
          key={`meadow--${i}`}
        />
      ))}
    </Box>
  );
};

export default Meadow;
