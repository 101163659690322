import Box from '@mui/material/Box';
import AccordionPanel from '../components/accordionPanel';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import PdfViewer from '../components/pdfViewer';

const Ofsted = () => {
    const ofstedSiteLink = <Link href="http://reports.ofsted.gov.uk/inspection-reports/find-inspection-report/provider/CARE/EY467601" target="_blank" rel="noopener">Ofsted website</Link>;
    
    const ofstedReports = [
        {
            id: 'ofsted-2018',
            title: 'November 2018 - Good',
            content: (
                <Box sx={{ height: '400px' }}>
                    <PdfViewer fileUrl="https://files.ofsted.gov.uk/v1/file/50045304" />
                </Box>
            )
        },
        {
            id: 'ofsted-2015',
            title: 'November 2015 - Good',
            content: (
                <Box sx={{ height: '400px' }}>
                    <PdfViewer fileUrl="https://files.ofsted.gov.uk/v1/file/2529201" />
                </Box>
            )
        }
    ]

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Ofsted
            </Typography>
            
            <Typography gutterBottom>
                I have been visited by Ofsted and have been granted the Registered status since September 2013.
                My Ofsted reports are viewable on the {ofstedSiteLink}, and are also viewable below for convenience:
            </Typography>

            {ofstedReports.map(report => <AccordionPanel {...report} />)}
            
            <Typography marginTop={4}>
                I am also qualified in the following areas:
                <ul>
                    <li>Attended a childcare course.</li>
                    <li>Credit Certificate in 'Understanding How To Set up a Home Based Childcare Service'.</li>
                    <li>Recent Paediatric First Aid Certificate.</li>
                    <li>Disclosure and Barring service check (formerly CRB).</li>
                    <li>Registered with Buckinghamshire Environmental Health Agency.</li>
                </ul>
            </Typography>
        </Box>
    );
};

export default Ofsted;
