import { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

const App = (props) => {
  const { tabContent } = props;
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  const [currentTab, setTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box
            sx={{
              p: isSmall ? 3 : 8,
              pt: 3
            }}
          >
            {children}
          </Box>
        )}
      </div>
    );
  };

  const a11yProps = (index) => ({
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  });

  return (
    <Box>
      <Box>
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          aria-label="main navigation"
        >
        {
          tabContent.map((tab, i) => (
            <Tab
              icon={tab.icon}
              label={isSmall ? undefined : tab.label}
              key={`${tab.label.replace(' ', '_')}`}
              {...a11yProps(i)}
              sx={{
                minWidth: isSmall ? '50px' : undefined
              }}
            />
          ))
        }
        </Tabs>
      </Box>
      {
        tabContent.map((tab, i) => (
          <TabPanel
            value={currentTab}
            index={i}
            key={`${tab.label.replace(' ', '_')}`}
          >
            {tab.content}
          </TabPanel>
        ))
      }
    </Box>
  );
};

export default App;
