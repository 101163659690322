import Box from '@mui/material/Box';

import AccordionPanel from '../components/accordionPanel';

import Typography from '@mui/material/Typography';

const accordionContent = [
    {
        id: 'home',
        title: 'In the Home',
        content: <Typography>
            As part of my risk assessment, I have ensured my home is suitable for caring for your child, this includes:
            <ul>
                <li>Having working smoke alarms</li>
                <li>Using a fire guard on the fireplace at all times</li>
                <li>Checking that the water is the correct temperature and adjusting if necessary</li>
            </ul>


            I will also make sure that my house is always clean:
            <ul>
                <li>Bedding and sheets will be regularly washed</li>
                <li>High chairs will always be cleaned and use the correct harnesses</li>
                <li>Push chairs will also use the correct harnesses</li>
                <li>The kitchen and bathroom will always be clean and regularly mopped</li>
                <li>Hygiene regulations are always followed in the preparation of food</li>
                <li>I will ensure that children never come into contact with rubbish</li>
                <li>
                    <ul>
                        <li>Rubbish and recycling will always be immediately placed in the correct bin</li>
                    </ul>
                </li>
                <li>Following hygiene guidelines, I will wear gloves whilst changing nappies</li>
                <li>Each child will have their own towel, which will be regularly washed</li>
            </ul>
        </Typography>
    },
    {
        id: 'garden',
        title: 'In the Garden',
        content: <Typography>
            Garden gates are always locked, either with a high up bolt or latch, or with a secure lock.<br />
            Tool shed doors will also have locks on, and children will not be allowed inside.
            <br />
            I will apply sun cream to children when it is provided by parents.<br />
            Children will also always wear sun hats when brought.
        </Typography>
    },
    {
        id: 'outside',
        title: 'Out and About',
        content: <Typography>
            While in and away from the home, I will always keep emergency contact details with me, should they be needed.<br />
            I will teach children about safety issues while walking though the village, such as crossing the road safely.<br />
            <br />
            If travelling in the car, I will ensure that all children are using an appropriately aged car seat, which will be securely fitted.<br />
            I will have child safety locks on all car doors.<br />
            My car will be regularly serviced and will always have an up to date MOT certificate.<br />
            I will always have a valid car tax certificate and have valid business insurance.
        </Typography>
    },
    {
        id: 'play',
        title: 'During Play',
        content: <Typography>
            Toys will be regularly checked for wear and tear and kept clean.<br />
            Children will not play with toys above their age specification.<br />
            Children will always be supervised during play.
        </Typography>
    },
    {
        id: 'pets',
        title: 'Pets and Animals',
        content: <Typography>
            The dog will never be left alone with children.<br />
            The dog food bowls will never be left out, except for their water bowl.<br />
            The cat and dog will always be treated for fleas, ticks and worms; this will be done on a regular basis to make sure that they are always safe.
        </Typography>
    }
];

const HealthAndSafety = () => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Health and Safety
            </Typography>

            <Typography
                sx={{marginBottom: 2}}
            >
                The safety of your child is paramount here, as such I have completed a full risk assessment of my home, and have outlined some of my policies below.<br />
                I will always follow the most recent hygiene regulations and safeguarding guidelines.<br />
                I have put in place procedures for what I will do in the event of a fire, illness, or accident.<br />
                Any sleeping children will be regularly monitored to ensure their safety.
            </Typography>

            {
                accordionContent.map(ac => <AccordionPanel {...ac} />)
            }

            <Typography
                sx={{marginTop: 2}}
            >
                For more information, please email for a full copy of my policies.
            </Typography>
        </Box>
    );
};

export default HealthAndSafety;
