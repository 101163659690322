import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const Main = () => {
    const marshGibbonPreschoolLink = <Link href="https://www.marsh-gibbon-pre-school.com" target="_blank" rel="noopener">Marsh Gibbon Preschool</Link>;

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Welcome!
            </Typography>
            
            <Typography>
                Butterflies Childminding is an all inclusive, friendly environment for your child to learn and play while not at home or in school.<br /><br />
                We aim to keep children safe and comfortable, especially if they are a little nervous at first.<br /><br />
                Butterflies itself has been running since 2013, though I also have years of experience as a nanny, looking after my own children, and as a childminder when my children were younger.<br /><br />
                I live within walking distance of {marshGibbonPreschoolLink}, so pick-ups and drop-offs are easy for me to do.<br /><br />
                As a group we go on nature walks through the village to learn about the animals and plants that live in the area.<br /><br />
                In the garden we also have ducks and chickens to watch and learn about when playing outside, as well as many flowers and plants.<br /><br />
                Please feel free to explore the rest of the website and contact me if you have any questions.<br /><br />
                Thank you for taking the time to look around,<br /><br />
                <img src={`${process.env.PUBLIC_URL}/graphics/signoff.svg`} alt="Jo D 🙂" /><br />
                Jo Dransfield
            </Typography>
        </Box>
    );
};

export default Main;
