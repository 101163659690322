import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import ProfileCard from '../components/profileCard';

const PetsAndAnimals = () => {
    const cards = [
        {
            name: 'Jax',
            caption: 'A small Jackapoo',
            summary: 'A fun and friendly dog who loves to play. He will never be left alone with children, and will always be fed away from them.',
            photo: `${process.env.PUBLIC_URL}/photos/Jax_outside_crop.jpg`
        },
        {
            name: 'Bailey',
            caption: 'An adventurous and active cat',
            summary: 'Isn\'t in a lot during the day but may be seen around from time to time.',
            photo: `${process.env.PUBLIC_URL}/photos/Bailey.jpeg`
        },
        {
            name: 'Chickens and Ducks',
            caption: 'Share the garden together',
            summary: 'The chickens are kept in an enclosed area, and share the garden with a few ducks who are fun to watch in the water, and always enjoy being thrown some bread.',
            photo: `${process.env.PUBLIC_URL}/photos/Chicken run.jpeg`
        }
    ];

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Pets and Animals
            </Typography>
            
            <Typography gutterBottom>
                There are a number of pets and animals living at the house.
                The dog and cat always have flea, tick and worming treatment to ensure that they are clean and safe.
            </Typography>

            <Grid
                container
                rowSpacing={3}
                columnSpacing={3}
                justifyContent='center'
                sx={{
                    mt: 1
                }}
            >
                {cards.map(c => (
                    <Grid
                        item
                        key={`pet-${c.name.replace(' ', '_')}`}
                        sm={12}
                        md={8}
                        lg={6}
                    >
                        <ProfileCard {...c} />
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
};

export default PetsAndAnimals;
