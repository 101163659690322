import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Tabs from './components/tabs';

import Banner from './components/banner';

import HomeIcon from '@mui/icons-material/Home';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import RestaurantIcon from '@mui/icons-material/Restaurant';
import PetsIcon from '@mui/icons-material/Pets';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import ContactMailIcon from '@mui/icons-material/ContactMail';

import Main from './pages/main';
import HealthAndSafety from './pages/health_safety';
import FoodAndDining from './pages/food_dining';
import PetsAndAnimals from './pages/pets_animals';
import FeesAndVacancies from './pages/fees_vacancies';
import Ofsted from './pages/ofsted';
import Contact from './pages/contact';

// import Footer from './components/footer';
import Meadow from './components/meadow';

import { Worker } from '@react-pdf-viewer/core';

const theme = createTheme({
  palette: {
    background: {
      main: '#6feaff',
      paper: '#fcfcfc',
    },
    text: {
      primary: '#173A5E',
      secondary: '#46505A',
    },
    border: {
      primary: '#bbb',
    }
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          h1: 'h3',
          h2: 'h3',
          h3: 'h3',
        }
      }
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
        target: '_blank',
        rel: 'noopener'
      }
    }
  }
});

function App() {
  const currentTheme = useTheme();
  const isSmall = useMediaQuery(currentTheme.breakpoints.down('sm'));

  const fontSize = isSmall ? "medium" : "small";

  const tabContent = [
    { label: "Home", icon: <HomeIcon fontSize={fontSize} />, content: <Main /> },
    { label: "Health and Safety", icon: <HealthAndSafetyIcon fontSize={fontSize} />, content: <HealthAndSafety /> },
    { label: "Food and Dining", icon: <RestaurantIcon fontSize={fontSize} />, content: <FoodAndDining /> },
    { label: "Pets and Animals", icon: <PetsIcon fontSize={fontSize} />, content: <PetsAndAnimals /> },
    { label: "Fees and Vacancies", icon: <ConfirmationNumberIcon fontSize={fontSize} />, content: <FeesAndVacancies /> },
    { label: "Ofsted Reports", icon: <FactCheckIcon fontSize={fontSize} />, content: <Ofsted  /> },
    { label: "Contact", icon: <ContactMailIcon fontSize={fontSize} />, content: <Contact /> },
  ];

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth={false}
        sx={{
          bgcolor: 'background.main',
          backgroundImage: 'linear-gradient(to bottom, rgb(157 239 253), rgb(47 207 255))',
          color: 'text.primary',
          pl: 2,
          pr: 2,
          width: 1,
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          overflowX: 'hidden'
        }}
      >
        <Container
          maxWidth="lg"
          sx={{
            p: isSmall ? 1 : 5
          }}
        >
          <Banner />
          <Box
            sx={{
              bgcolor: 'background.paper',
              boxShadow: 1,
              borderRadius: 2,
              p: 1,
              minHeight: '50vh'
            }}
          >
            <Tabs
              tabContent={tabContent}
            />
          </Box>
        </Container>
        {/* <Footer /> */}
        <Meadow />
      </Container>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.13.216/build/pdf.worker.min.js"></Worker>
    </ThemeProvider>
  );
}

export default App;
