import { Viewer } from '@react-pdf-viewer/core';

import '@react-pdf-viewer/core/lib/styles/index.css';

const PdfViewer = (props) => {
    const { fileUrl } = props;

    return <Viewer fileUrl={fileUrl} />;
};

export default PdfViewer;
