import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import ContactForm from '../components/contactForm';

const Contact = () => {
    const childcareLink = <Link href="https://www.childcare.co.uk/profile/Jo-Dransfield/" target="_blank" rel="noopener">childcare.co.uk</Link>;

    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Contact
            </Typography>
            
            <Typography gutterBottom>
                If you have any further queries or questions, or you'd like a full copy of the policies please complete the following form.
            </Typography>
            
            <Typography gutterBottom>
                Please also check out my profile on {childcareLink}.
            </Typography>
            
            <ContactForm />
        </Box>
    );
};

export default Contact;
