import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FoodAndDining = () => {
    return (
        <Box>
            <Typography variant="h4" gutterBottom>
                Food and Dining
            </Typography>
            
            <Typography gutterBottom>
                Snacks provided will be healthy, and take any known dietary conditions into consideration.
            </Typography>

            <Typography variant="h5">
                Breakfast
            </Typography>

            <Typography gutterBottom>
                Breakfast will be cereal and/or toast. There will also be a mid-morning snack of fruit for those who want it.
            </Typography>

            <Typography variant="h5">
                Lunch
            </Typography>

            <Typography>
                A packed lunch should be provided with your children when dropping them off.
            </Typography>
        </Box>
    );
};

export default FoodAndDining;
