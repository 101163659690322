import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

const AccordionPanel = (props) => {
    const { id, title, content } = props;

    return (
        <Accordion
            elevation={3}
        >
            <AccordionSummary
                id={`accordion-panel-${id}`}
                expandIcon={<ExpandMoreIcon />}
            >
                <Typography variant="h5">{title}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>{content}</Typography>
            </AccordionDetails>
        </Accordion>
    );
};

export default AccordionPanel;
